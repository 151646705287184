import { FC } from 'react';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import ActionEditorBase from '../ActionEditorBase';

const FileOrLinkUploadResourceEditor: FC<EditorProps> = (props) => {
  return (
    <div data-cy="file-or-link-upload-editor">
      <ActionEditorBase {...props}></ActionEditorBase>
    </div>
  );
};

export default FileOrLinkUploadResourceEditor;
